import React from 'react'

import { useRoutes, Link } from 'raviger'
import { NavBar } from '@nike/epic-react-ui'


import config from '../config'
import routes from './routes'
import NotFoundPage from './NotFoundPage'
import OfflineBanner from '../offline/OfflineBanner'

const navBarLinks = [{ text: 'About', path: '/about' }]

const App = () => {
  const routeResult = useRoutes(routes) || <NotFoundPage />

  return (
    <div>
      <OfflineBanner />
      <div>
        <NavBar
          name={config.appFullName}
          logo="/logo.png"
          rootPath="/"
          routes={navBarLinks}
          RouterLink={Link}
        />
        <div style={{ margin: '1rem' }}>{routeResult}</div>
      </div>
    </div>
  )
}

export default App
