import { combineReducers } from 'redux'
import { parseErrorMiddleware } from '@nike/redux-action-utils'
import offline from '../offline/reducer'
import notifications from '../notifications/reducer'

const rootReducer = combineReducers({
  parseErrorMiddleware,
  offline,
  notifications
})

export default rootReducer
