import React from 'react'

import config from '../config'
import StyleColorForm from '../components/StyleColorForm'

const HomePage = () => (
  <div>
    <p>Welcome to {config.appFullName}</p>
    <StyleColorForm/>
  </div>
)

export default HomePage
