import React from 'react'

const AboutPage = () => (
  <div>
    <p>
      This site was generated by{' '}
      <a href="https://rocket.niketech.com">Rocket</a>.
    </p>
  </div>
)

export default AboutPage
