import merge from 'deepmerge'

const base = {
  appName: 'StyleColor-to-GTIN',
  appFullName: 'StyleColor-to-GTIN'
}

const dev = merge(base, { apiUrl: '/dev' })

const prod = merge(base, { apiUrl: '/prod' })

const config = window.location.origin.includes('//') ? prod : dev

export default config
