import React from 'react'

import HomePage from '../home/HomePage'
import AboutPage from '../about/AboutPage'

const routes = {
  '/': () => <HomePage />,
  '/about': () => <AboutPage />
}

export default routes
