import React from 'react'
import '../stylesheet.css';
const regex = "^[A-Z0-9]{5,12}-[A-Z0-9]{3}$";
var assert = require('assert');

class StyleColorForm extends React.Component {constructor (props) {
  super(props)
  this.state = {
    styleColor: 'CK2612-500\nCJ0620-600\nCJ0280-003',
    skus: [],
    isLoading: true,
    codes: [],
    errors: [],
    skuMap: []
  }

  this.handleChange = this.handleChange.bind(this)
  this.handleSubmit = this.handleSubmit.bind(this)
}

handleChange (event) {
  this.setState({ styleColor: event.target.value })
}

handleSubmit (event) {
  event.preventDefault()

  this.setState(
    { codes: this.state.styleColor.split(/\r?\n/), errors: [] },
    () => {
      const skuMap = {}
      const validCodes = []
      const invalidCodes = []
      // make the calls now!
      for (const code of this.state.codes) {
        if (code.match(regex)) {
          validCodes.push(code)
        } else {
          invalidCodes.push(code)
        }
      }
      console.log('validCodes: ' + validCodes)
      Promise.all(validCodes.map(validCode =>
        fetch(
          `https://api.nike.com/product_feed/threads/v2?filter=channelId(d9a5bc42-4b9c-4976-858a-f159cf99c647)&filter=language(en)&filter=marketplace(US)&filter=publishedContent.properties.products.styleColor(${validCode})&fields=id,marketplace,channelId,productInfo.skus.gtin,productInfo.availableSkus,productInfo.skus, productInfo.imageUrls,productInfo.merchProduct.styleColor`
        ).then(response => response.json())
      )).then(productResults => {
        console.log(productResults)
        for (const result of productResults) {
          console.log(result)
          const product = result.objects[0]
          const styleColor =
            product.productInfo[0].merchProduct.styleColor
          const availableSkuId = product.productInfo[0].availableSkus?
            product.productInfo[0].availableSkus[0]?.id: ""
          const sku = {}
          console.log('Style Color:' + styleColor)
          console.log('Available Sku ID:' + availableSkuId)
          console.log('Sku:' + sku)
          if (
            Object.prototype.hasOwnProperty.call(result.objects[0].productInfo[0],
              'availableSkus'
            )
          ) {
            sku.gtin = (product?.productInfo[0]?.skus?.find(
              (sku) => sku.id === availableSkuId
            ).gtin) ||  "NA"
            
          } else {
            sku.gtin = 'OOS'
          }
          sku.imageUrl =
              product?.productInfo[0]?.imageUrls?.productImageUrl
          sku.styleColor = styleColor
          skuMap[styleColor] = sku
        }
        console.log('skuMap' + JSON.stringify(skuMap))
        this.setState({ skuMap: skuMap })
        // Now set the skus order and initialize errors
        const foundCodes = Object.keys(skuMap)
        const missingCodes = validCodes.filter(
          (v) => foundCodes.indexOf(v) === -1
        )
        const badRegexErrors = invalidCodes.map((e) => {
          return { message: `Invalid style-color code format: ${e}` }
        })
        const notFoundErrors = missingCodes.map((e) => {
          return { message: `Style-color code does not exist:  ${e}` }
        })

        this.setState((prevState) => ({
          errors: [
            ...prevState.errors,
            ...badRegexErrors,
            ...notFoundErrors
          ]
        }))

        const skus = []
        // Now we add the skus to the display list in order
        for (const vc of validCodes) {
          if (foundCodes.indexOf(vc) > -1) {
            skus.push(skuMap[vc])
          }
        }

        this.setState({ skus: skus })
      })
    }
  )
}

render () {
  return (
    <div>
      <form onSubmit={this.handleSubmit}>
        <label>
          Style-Color Codes:  
          <textarea style={{"verticalAlign": "top", "marginLeft": "4px"}} type="text" rows="6" value={this.state.styleColor} onChange={this.handleChange} />
        </label>
        <input style={{"verticalAlign": "bottom", "marginLeft": "4px"}} type="submit" value="Submit" />
        
      </form>

      {(this.state.skus.length > 0) &&
        <table style={{"width": "100%"}}>
          <thead>
            <tr>
              <th>Style Color</th>
              <th>GTIN</th>
              <th>Thumbnail</th>
            </tr>
            </thead>
          <tbody>
            {this.state.skus.map(function(sku, i){
              return <tr key={i}> 
                <td>{sku.styleColor}</td>
                <td>{sku.gtin}</td> 
                <td><img src={sku.imageUrl} style={{"width":128}} /> </td>
              </tr>
            })}
          </tbody>
        </table>
     }
      {this.state.errors.length > 0 && (
        <div>
          Errors:{' '} 
          <ul>
          {this.state.errors.map((error, i) => (
            <li key={i}>{error.message}</li>
          ))}
          </ul>
        </div>
      )}
    </div>
  )
}
}

export default StyleColorForm;