import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import rootReducer from './reducer'

export const HYDRATE_STATE = 'HYDRATE_STATE'

// If we ever do server-side rendering, the initial state needs to check
// for pre-hydrated data, probably in window.__initialState
const initialState = {}

const configureStore = () => {
  const store = createStore(
    makeHydratable(rootReducer, HYDRATE_STATE),
    initialState,
    composeWithDevTools(
      applyMiddleware(
        thunkMiddleware)
    )
  )

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducer', () => {
        store.replaceReducer(rootReducer)
      })
    }
  }

  return store
}

export default configureStore

function makeHydratable (reducer, hydrateActionType) {
  return function (state, action) {
    switch (action.type) {
      case hydrateActionType:
        return reducer(action.state, action)
      default:
        return reducer(state, action)
    }
  }
}
