import { createAction } from '@nike/redux-action-utils'

export const addNotification = createAction('NOTIFICATIONS_ADD')
export const removeNotification = createAction('NOTIFICATIONS_REMOVE')
export const clearNotifications = createAction('NOTIFICATIONS_CLEAR_ALL')

let notificationId = 0
export const notify = ({
  message,
  closeOnClick = true,
  type = 'success',
  timeoutMs = 3000
}) => dispatch => {
  notificationId++
  // create closed value so other notifications' incrementing doesn't interfere with timeout
  const id = notificationId
  dispatch(addNotification({ message, type, closeOnClick, id }))

  if (timeoutMs !== 0) {
    setTimeout(() => {
      dispatch(removeNotification(id))
    }, timeoutMs)
  }

  return id
}

export const notifyError = ({ message }) => {
  return notify({ message, closeOnClick: true, type: 'error', timeoutMs: 0 })
}
